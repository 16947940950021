import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function DataVisualization() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Data Visualization</Title>
                <Paragraph>
                    Transform complex data into meaningful visualizations with our Data Visualization services. Our tools allow you to see patterns, trends, and outliers, making data easier to understand and act upon.
                </Paragraph>
                <Paragraph>
                    Whether you need interactive dashboards or clear and concise charts, our data visualization solutions help bring clarity to your decision-making processes.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default DataVisualization;
