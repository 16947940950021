import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function MachineLearning() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Machine Learning</Title>
                <Paragraph>
                    Our Machine Learning services unlock the potential of AI for your business. We build custom models that learn from your data to make predictions, automate processes, and improve outcomes across a range of industries.
                </Paragraph>
                <Paragraph>
                    Whether you're looking to develop recommendation systems, predictive analytics, or automate tasks, our machine learning solutions deliver measurable results.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default MachineLearning;
