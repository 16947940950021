import React, { useState } from 'react';

// import { Link } from 'react-router-dom';
import { Layout, Row, Col, Card, Typography, Button, List, Pagination, Menu, Collapse, Tag, Input, Space } from 'antd';
import { FaEye } from 'react-icons/fa'; // Import the eye icon from react-icons

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const newsData = [
    {
        title: 'AI is Transforming Industries: Discover How',
        date: 'September 4, 2024',
        summary: 'Artificial Intelligence is at the forefront of innovation across various sectors, revolutionizing everything from healthcare to finance.',
        image: '/images/ai-news-1.jpeg',
        link: '/news/ai-transforming-industries',
        tags: ['Artificial Intelligence', 'Cybersecurity', 'Data Science'],
        views: 1234
    },
    {
        title: 'Cybersecurity in the Age of AI: Challenges and Opportunities',
        date: 'September 3, 2024',
        summary: 'As AI continues to evolve, it brings new challenges and opportunities in the field of cybersecurity. Learn how AI is both a threat and a solution.',
        image: '/images/cybersecurity-news-1.jpeg',
        link: '/news/cybersecurity-ai-challenges',
        tags: ['Cybersecurity', 'Artificial Intelligence'],
        views: 5678
    },
    {
        title: 'Data Science: Unlocking the Power of Big Data',
        date: 'September 2, 2024',
        summary: 'Data Science is transforming industries by unlocking insights from massive datasets. Explore how companies are leveraging data to drive innovation.',
        image: '/images/data-science-news-1.jpeg',
        link: '/news/data-science-big-data',
        tags: ['Data Science', 'Artificial Intelligence'],
        views: 4321
    },
    {
        title: 'AI and Ethics: Navigating the Moral Complexities',
        date: 'September 1, 2024',
        summary: 'The rise of AI has brought with it significant ethical challenges. This article explores the moral implications of AI in modern society.',
        image: '/images/cybersecurity-news-1.jpeg',
        link: '/news/ai-ethics',
        tags: ['Artificial Intelligence', 'Ethics'],
        views: 5678
    },
    // Add more news items here
];

const archives = [
    { year: 2024, months: ['January', 'February', 'March'] },
    { year: 2023, months: ['January', 'February', 'March'] }
];

function Newsroom() {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 6; // Number of news items per page

    // Handle page change
    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Paginated news data
    const paginatedNews = newsData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // Related topics - assuming we're just taking the first 5 for simplicity
    const relatedTopics = newsData.slice(0, 5);

    return (
        <Layout>
            <Header style={{ backgroundColor: '#001529', padding: '0 50px' }}>
                <Title level={2} style={{ color: '#fff', margin: '16px 0' }}>Newsroom</Title>
            </Header>

            <Content style={{ padding: '50px', backgroundColor: '#f0f2f5' }}>
                <Row gutter={[16, 16]}>
                    <Col span={18}> {/* Increase the width of the left column */}
                        {/* Left Column: News with Pagination */}
                        <List
                            grid={{ gutter: 16, column: 2 }} // Two columns
                            dataSource={paginatedNews}
                            renderItem={item => (
                                <List.Item>
                                    <Card
                                        hoverable
                                        cover={<img alt={item.title} src={item.image} />}
                                    >
                                        <Card.Meta title={item.title} description={item.date} />
                                        <Paragraph>{item.summary}
                                            <Button type="link" href={item.link}>Read More</Button></Paragraph>
                                        <div style={{ marginTop: '10px' }}>
                                            <div>
                                                {item.tags.map(tag => (
                                                    <Tag key={tag}>{tag}</Tag>
                                                ))}
                                            </div>
                                            <Paragraph style={{ marginTop: '5px', display: 'flex', alignItems: 'center', fontSize:'11px' }}>
                                                <FaEye style={{ marginRight: '5px' }} /> {item.views}
                                            </Paragraph>
                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={newsData.length}
                            onChange={onPageChange}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                    </Col>
                    <Col span={6}> {/* Reduce the width of the right column */}
                        {/* Right Column: Archived News */}
                        <Title level={4}>Archived News</Title>
                        <Collapse>
                            {archives.map(archive => (
                                <Panel header={archive.year} key={archive.year}>
                                    <Menu mode="inline">
                                        {archive.months.map(month => (
                                            <Menu.Item key={`${archive.year}-${month}`}>
                                                {month} {archive.year}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                </Panel>
                            ))}
                        </Collapse>

                        {/* Related Topics Section */}
                        <Title level={4} style={{ marginTop: '20px' }}>Related Topics</Title>
                        <List
                            size="small"
                            dataSource={relatedTopics}
                            renderItem={item => (
                                <List.Item>
                                    <Button type="link" href={item.link} style={{ padding: '0' }}>{item.title}</Button>
                                </List.Item>
                            )}
                        />

                        {/* Subscribe to Our Newsletter Section */}
                        <Title level={4} style={{ marginTop: '20px' }}>Subscribe to Our Newsletter</Title>
                        <Paragraph>Get the latest AI news and updates delivered to your inbox.</Paragraph>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Input placeholder="Enter your email" />
                            <Button type="primary" block>Subscribe</Button>
                        </Space>
                    </Col>
                </Row>
            </Content>

        </Layout>
    );
}

export default Newsroom;
