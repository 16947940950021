import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function AIInterviews() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>AI Interviews</Title>
                <Paragraph>
                    AI Interviews provide real-time insights during candidate assessments, analyzing responses for key indicators such as communication skills, emotional intelligence, and technical expertise.
                </Paragraph>
                <Paragraph>
                    This innovative tool enables companies to conduct more objective interviews, reducing the impact of human biases and improving the overall hiring process.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default AIInterviews;
