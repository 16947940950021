import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function AIDrivenAssessments() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>AI-Driven Assessments</Title>
                <Paragraph>
                    Our AI-Driven Assessments help organizations evaluate candidate and employee skills based on real-world scenarios and tasks. These assessments provide detailed reports on technical, analytical, and problem-solving abilities.
                </Paragraph>
                <Paragraph>
                    By leveraging AI technology, companies can now assess applicants at scale and make data-driven hiring decisions.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default AIDrivenAssessments;
