import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function IncidentResponse() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Incident Response</Title>
                <Paragraph>
                    Our Incident Response services provide a rapid and effective way to address cybersecurity breaches and minimize their impact. Our AI-driven platform automates threat identification, analysis, and containment, allowing you to respond to incidents faster.
                </Paragraph>
                <Paragraph>
                    Ensure your business is prepared for any security event with our comprehensive incident management and recovery plans.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default IncidentResponse;
