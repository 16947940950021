import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function CybersecurityTraining() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Cybersecurity Training</Title>
                <Paragraph>
                    Empower your employees with the knowledge and skills to defend against cyber threats. Our Cybersecurity Training programs cover topics such as phishing attacks, malware prevention, and best security practices.
                </Paragraph>
                <Paragraph>
                    Equip your team with practical, hands-on training that will strengthen your organization’s defenses and create a security-first culture.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default CybersecurityTraining;
