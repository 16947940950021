import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function DataModeling() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Data Modeling</Title>
                <Paragraph>
                    Data Modeling is the backbone of effective data management and analysis. Our solutions ensure that your data is structured in a way that maximizes its usability and consistency across your organization.
                </Paragraph>
                <Paragraph>
                    From relational databases to more complex data architectures, our AI-powered data modeling techniques provide accurate and flexible models that adapt to your business needs.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default DataModeling;
