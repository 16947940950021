import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function AIPlagiarismDetection() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>AI Plagiarism Detection</Title>
                <Paragraph>
                    Our AI Plagiarism Detection tool scans documents for duplicate content, ensuring originality and academic integrity. By comparing submissions to a vast database of online and offline resources, it helps organizations and educational institutions prevent plagiarism.
                </Paragraph>
                <Paragraph>
                    This tool is particularly useful for academic institutions, publishers, and businesses producing high volumes of written content.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default AIPlagiarismDetection;
