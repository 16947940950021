import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function RiskManagement() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Risk Management</Title>
                <Paragraph>
                    Our Risk Management solutions provide organizations with a comprehensive approach to identifying, assessing, and mitigating cyber risks. We use AI and data analytics to prioritize risks based on potential impact and severity.
                </Paragraph>
                <Paragraph>
                    With our tools, companies can make informed decisions on how to allocate resources and enhance their cybersecurity posture.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default RiskManagement;
