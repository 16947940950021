import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Drawer, Carousel } from 'antd';
import { MenuOutlined, MessageOutlined } from '@ant-design/icons';
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import SignInModal from './components/SignInModal';
import SignUpModal from './components/SignUpModal';
import SearchFilter from './components/SearchFilter';
import FooterComponent from './components/FooterComponent';
import MainContent from './components/MainContent';
import MenuItems from './components/MenuItems';
import './assets/styles/App.css';
import './assets/styles/PopupDrawer.css';

// About JectHub
import AICareers from './pages/AboutJectHub/AICareers';
import Newsroom from './pages/AboutJectHub/Newsroom';

// AI Products
import AIDrivenAssessments from './pages/AIProducts/AIDrivenAssessments';
import AIInterviews from './pages/AIProducts/AIInterviews';
import AIPlagiarismDetection from './pages/AIProducts/AIPlagiarismDetection';
import AIScreening from './pages/AIProducts/AIScreening';
import RealWorldAIChallenges from './pages/AIProducts/RealWorldAIChallenges';

// Cybersecurity
import ComplianceSolutions from './pages/Cybersecurity/ComplianceSolutions';
import CybersecurityTraining from './pages/Cybersecurity/CybersecurityTraining';
import IncidentResponse from './pages/Cybersecurity/IncidentResponse';
import RiskManagement from './pages/Cybersecurity/RiskManagement';
import ThreatDetection from './pages/Cybersecurity/ThreatDetection';

// Data Science
import DataAnalytics from './pages/DataScience/DataAnalytics';
import DataVisualization from './pages/DataScience/DataVisualization';
import DataModeling from './pages/DataScience/DataModeling';
import MachineLearning from './pages/DataScience/MachineLearning';
import BigDataSolutions from './pages/DataScience/BigDataSolutions';

// Slider images
import slider1 from './images/slider1.jpg';
import slider2 from './images/slider2.jpg';
import slider3 from './images/slider3.jpg';
import slider4 from './images/slider4.jpg';
import slider5 from './images/slider5.jpg';

const { Header, Content } = Layout;

function App() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [chatVisible, setChatVisible] = useState(false); // For chat drawer

  useEffect(() => {
    // Show popup after the page loads
    const timer = setTimeout(() => {
      setPopupVisible(true);
    }, 1000); // Show popup after 1 second

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const showChat = () => {
    setChatVisible(true); // Open chat drawer
  };

  const closeChat = () => {
    setChatVisible(false); // Close chat drawer
  };

  return (
      <Router>
        <Layout className="layout">
          <Header className="header">
            <div className="logo">
              <img src="/images/logo.png" alt="JectHub Logo" className="logo-image" />
            </div>
            <Button
                className="mobile-menu-button"
                type="primary"
                onClick={showDrawer}
                icon={<MenuOutlined />}
            />
            <Menu
                theme="light"
                mode="horizontal"
                className="menu"
                style={{ backgroundColor: '#50b0e0' }}
            >
              <MenuItems closeDrawer={closeDrawer} />
            </Menu>
            <Drawer
                title="Menu"
                placement="right"
                closable={true}
                onClose={closeDrawer}
                open={drawerVisible}
            >
              <Menu
                  mode="vertical"
                  style={{ backgroundColor: '#50b0e0' }}
                  onClick={closeDrawer}
              >
                <MenuItems closeDrawer={closeDrawer} />
              </Menu>
            </Drawer>

            {/* Chat Support Button */}
            <Button
                className="chat-support-button"
                type="primary"
                shape="circle"
                icon={<MessageOutlined />}
                size="large"
                onClick={showChat}
                style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
            />
          </Header>

          <Content style={{ padding: 0, margin: 0 }}>
            <div className="sliders-section">
              <Carousel autoplay className="carousel">
                {[slider1, slider2, slider3, slider4, slider5].map((slider, index) => (
                    <div key={index} className="carousel-slide">
                      <img src={slider} alt={`Slider ${index + 1}`} className="slider-image" />
                      <div className="slider-content">
                        {index === 0 && (
                            <>
                              <h2>Innovate with JectHub</h2>
                              <p>Join our community to turn ideas into reality.</p>
                            </>
                        )}
                        {index === 1 && (
                            <>
                              <h2>Challenge Yourself</h2>
                              <p>Participate in challenges to test and expand your skills.</p>
                            </>
                        )}
                        {index === 2 && (
                            <>
                              <h2>Collaborate on Projects</h2>
                              <p>Work on projects with peers to gain real-world experience.</p>
                            </>
                        )}
                        {index === 3 && (
                            <>
                              <h2>Learn from the Best</h2>
                              <p>Access mentorship from industry experts.</p>
                            </>
                        )}
                        {index === 4 && (
                            <>
                              <h2>Stay Updated</h2>
                              <p>Get the latest news and trends in AI and technology.</p>
                            </>
                        )}
                      </div>
                    </div>
                ))}
              </Carousel>
            </div>
            <div className="search-filter-container indigo darken-2 white-text center" style={{ padding: '5px 0' }}>
              <SearchFilter />
            </div>

            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/news" element={<Newsroom />} />
              <Route path="/careers" element={<AICareers />} />
            </Routes>
          </Content>

          <FooterComponent />

          <Routes>
            <Route path="/signin" element={<SignInModal />} />
            <Route path="/signup" element={<SignUpModal />} />
          </Routes>

          {/* Popup Drawer for Ads */}
          <Drawer
              title="Exclusive Offers"
              placement="right"
              closable={true}
              onClose={closePopup}
              open={popupVisible}
              width={300}
              bodyStyle={{ maxHeight: '500px', paddingBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <div>
              <h3>Get a Free AI Course</h3>
              <p>Sign up for our AI mentorship program and get access to free learning resources.</p>
              <Link to="/signup"> {/* Use Link for routing */}
                <Button type="primary">
                  Apply Now
                </Button>
              </Link>
            </div>
            <div>
              <h3>Exclusive Cybersecurity Training</h3>
              <p>Join our cybersecurity training program and protect organizations from threats.</p>
              <Link to="/signup"> {/* Use Link for routing */}
                <Button type="primary">
                  Apply Now
                </Button>
              </Link>
            </div>
            <div>
              <h3>Data Science Bootcamp</h3>
              <p>Learn data science skills with real-world projects and expert mentorship.</p>
              <Link to="/signup"> {/* Use Link for routing */}
                <Button type="primary">
                  Apply Now
                </Button>
              </Link>
            </div>

            {/* News Section */}
            <div className="news-container">
              <div className="news-scroll">
                <p>
                  <a href="/ai-features" className="news-link">🚀 New AI features launching this month!</a>
                </p>
                <p>
                  <a href="/webinar" className="news-link">🔒 Join our upcoming webinar on Cybersecurity trends.</a>
                </p>
                <p>
                  <a href="/data-science-resources" className="news-link">📊 Check out our latest Data Science resources!</a>
                </p>
              </div>
            </div>
          </Drawer>

          {/* Chat Support Drawer */}
          <Drawer
              title="Chat Support"
              placement="right"
              closable={true}
              onClose={closeChat}
              open={chatVisible}
              width={350}
          >
            <div style={{ padding: '20px' }}>
              <h3>How can we assist you today?</h3>
              <p>Feel free to ask any questions or seek support from our team.</p>
              <input
                  type="text"
                  placeholder="Type your message..."
                  style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              />
              <Button type="primary" style={{ width: '100%' }}>
                Send
              </Button>
              <div style={{ marginTop: '20px' }}>
                <h4>Need immediate help?</h4>
                <Button
                    type="primary"
                    onClick={() => window.open('https://wa.me/254708031478', '_blank')}
                    style={{ width: '100%' }}
                >
                  Chat on WhatsApp
                </Button>
              </div>
            </div>
          </Drawer>
        </Layout>
      </Router>
  );
}

export default App;
