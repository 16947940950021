import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function BigDataSolutions() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Big Data Solutions</Title>
                <Paragraph>
                    Our Big Data Solutions enable businesses to process, analyze, and extract value from vast amounts of data. We leverage scalable technologies like Hadoop and Spark to handle high-velocity, high-volume data from various sources.
                </Paragraph>
                <Paragraph>
                    With our solutions, you can unlock insights that drive innovation and optimize your operations, ensuring your business stays ahead in the data-driven world.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default BigDataSolutions;
