import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function DataAnalytics() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Data Analytics</Title>
                <Paragraph>
                    Our Data Analytics services allow businesses to harness the power of their data by extracting actionable insights. We leverage advanced statistical techniques and machine learning algorithms to analyze trends, patterns, and anomalies in your data.
                </Paragraph>
                <Paragraph>
                    Gain a competitive edge by making data-driven decisions that optimize your business performance and improve customer satisfaction.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default DataAnalytics;
