import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function RealWorldAIChallenges() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Real World AI Challenges</Title>
                <Paragraph>
                    Participate in our Real World AI Challenges, where you can apply AI and machine learning techniques to solve complex, real-world problems. These challenges are designed to push your creativity, critical thinking, and problem-solving skills to the limit.
                </Paragraph>
                <Paragraph>
                    Whether you're a student, a researcher, or a professional, our AI challenges offer a platform to showcase your talent and contribute to cutting-edge solutions.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default RealWorldAIChallenges;
