import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function ComplianceSolutions() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Compliance Solutions</Title>
                <Paragraph>
                    Our Compliance Solutions help organizations meet industry regulations and standards such as GDPR, HIPAA, and ISO 27001. With automated compliance tracking and reporting, you can ensure that your business is always in line with legal and ethical guidelines.
                </Paragraph>
                <Paragraph>
                    Our AI tools streamline the compliance process, reducing the burden of manual checks and improving accuracy.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default ComplianceSolutions;
