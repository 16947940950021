import React from 'react';
import { Row, Col, Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import '../assets/styles/Footer.css'; // Ensure this CSS file is correctly linked

const { Footer } = Layout;
const { Title, Text } = Typography;

const FooterComponent = () => (
    <div>
        <Footer className="footer">
            <div className="footer-content">
                <Row gutter={[16, 24]} justify="start">
                    {/* AI Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">AI Products</Title>
                        <ul className="footer-links">
                            <li><Link to="/screening" className="footer-link">AI Screening</Link></li>
                            <li><Link to="/interviews" className="footer-link">AI Interviews</Link></li>
                            <li><Link to="/assessments" className="footer-link">AI-Driven Assessments</Link></li>
                            <li><Link to="/plagiarism" className="footer-link">AI Plagiarism Detection</Link></li>
                            <li><Link to="/real-world" className="footer-link">Real World AI Challenges</Link></li>
                        </ul>
                    </Col>
                    {/* Cybersecurity Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Cybersecurity</Title>
                        <ul className="footer-links">
                            <li><Link to="/cyber-threat-detection" className="footer-link">Threat Detection</Link></li>
                            <li><Link to="/cyber-risk-management" className="footer-link">Risk Management</Link></li>
                            <li><Link to="/cyber-compliance" className="footer-link">Compliance Solutions</Link></li>
                            <li><Link to="/cyber-training" className="footer-link">Cybersecurity Training</Link></li>
                            <li><Link to="/cyber-response" className="footer-link">Incident Response</Link></li>
                        </ul>
                    </Col>
                    {/* Data Science Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Data Science</Title>
                        <ul className="footer-links">
                            <li><Link to="/data-analytics" className="footer-link">Data Analytics</Link></li>
                            <li><Link to="/data-visualization" className="footer-link">Data Visualization</Link></li>
                            <li><Link to="/data-modeling" className="footer-link">Data Modeling</Link></li>
                            <li><Link to="/machine-learning" className="footer-link">Machine Learning</Link></li>
                            <li><Link to="/big-data" className="footer-link">Big Data Solutions</Link></li>
                        </ul>
                    </Col>
                    {/* About JectHub Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">About JectHub</Title>
                        <ul className="footer-links">
                            <li><Link to="/Careers" className="footer-link">AI Careers</Link></li>
                            <li><Link to="/ai-team" className="footer-link">Our AI Team</Link></li>
                            <li><Link to="/news" className="footer-link">Newsroom</Link></li>
                            <li><Link to="/ai-status" className="footer-link">AI System Status</Link></li>
                            <li><Link to="/ai-trust" className="footer-link">AI Trust & Safety</Link></li>
                        </ul>
                    </Col>
                    {/* Get Started with AI Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Get Started with AI</Title>
                        <ul className="footer-links">
                            <li><Link to="/ai-pricing" className="footer-link">AI Pricing</Link></li>
                            <li><Link to="/ai-free-trial" className="footer-link">AI Free Trial</Link></li>
                            <li><Link to="/ai-contact" className="footer-link">Contact AI Team</Link></li>
                            <li><Link to="/ai-demo" className="footer-link">Request AI Demo</Link></li>
                            <li><Link to="/ai-support" className="footer-link">AI Product Support</Link></li>
                            <li><Link to="/ai-developers" className="footer-link">AI for Developers</Link></li>
                        </ul>
                    </Col>
                    {/* Internships & Premium Services Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Premium Services</Title>
                        <ul className="footer-links">
                            <li><Link to="/internships" className="footer-link">Internship Opportunities</Link></li>
                            <li><Link to="/premium-services" className="footer-link">Premium Services</Link></li>
                            <li><Link to="/mentorship-programs" className="footer-link">Mentorship Programs</Link></li>
                            <li><Link to="/exclusive-content" className="footer-link">Exclusive Content</Link></li>
                            <li><Link to="/advanced-courses" className="footer-link">Advanced Courses</Link></li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </Footer>
        <div className="footer-bottom">
            <div className="footer-bottom-content">
                <Text className="footer-text">© 2024 JectHub - Empowering the Future with AI.</Text>
                <div className="footer-social">
                    <a href="https://www.facebook.com/jecthub" className="footer-social-link" target="_blank" rel="noopener noreferrer">
                        <FaFacebookF />
                    </a>
                    <a href="https://x.com/JectHub" className="footer-social-link" target="_blank" rel="noopener noreferrer">
                        <FaTwitter />
                    </a>
                    <a href="https://www.linkedin.com/company/jecthub/" className="footer-social-link" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn />
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default FooterComponent;
