import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function ThreatDetection() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>Threat Detection</Title>
                <Paragraph>
                    Our AI-powered Threat Detection system helps organizations identify and mitigate security threats in real-time. Utilizing advanced algorithms, the system can detect anomalies, suspicious activities, and potential attacks before they cause harm.
                </Paragraph>
                <Paragraph>
                    Stay ahead of cybercriminals with proactive monitoring and threat intelligence that ensures the safety of your data and systems.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default ThreatDetection;
