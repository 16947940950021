import React from 'react';

// import { Link } from 'react-router-dom';
import { Layout, Row, Col, Card, Typography, Button, List, Pagination, Menu, Collapse, Tag, Input, Space } from 'antd';
import { FaEye } from 'react-icons/fa'; // Import the eye icon from react-icons

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


function AICareers() {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={2}>AI Careers</Title>
                <Paragraph>
                    Join the forefront of innovation by becoming part of our AI team. We're looking for talented individuals passionate about Artificial Intelligence, Machine Learning, and Data Science. Explore our career opportunities and shape the future of AI with JectHub.
                </Paragraph>
                <Paragraph>
                    Whether you're a data scientist, AI engineer, or researcher, JectHub offers exciting roles that let you grow and make a difference.
                </Paragraph>
            </Content>
        </Layout>
    );
}

export default AICareers;
